<template>
	<div class="container-fluid">
		<b-jumbotron>
			<h1>Secci&oacute;n 3 <small>Restaurante Mantura</small></h1>
		</b-jumbotron>
		<b-jumbotron>
			<h2>Seccion 3.1 Restaurante</h2>
			<img alt="seccion 3.1" src="../../assets/seccion1.4.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '1.4'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="1.4"></arges-tarjetas-lista>
		</b-jumbotron>		
	</div>
</template>

<script>
	
	import ArgesTarjetasLista from '@/components/tarjetas/ArgesTarjetasLista.vue'

	export default {
		name: 'Seccion3View',
		components: {
			ArgesTarjetasLista
		}
	}
</script>
