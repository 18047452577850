import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import "./plugins/bootstrap-vue"
import App from './App.vue'
import router from './router'
import store from './store'

// Bootstrap
import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
Vue.use(BootstrapVue)

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
//import { faUser, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUser, faLock)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Axios from 'axios'
//console.log(process.env.VUE_APP_API_ENDPOINT);
Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
// Add a request interceptor // TODO Get Token
Axios.interceptors.request.use( (config: any) => {
	const token = store.getters['seguridad/GET_TOKEN']
	config.headers.common['X-Access-Token'] = token;

	return config;	
});

// Upload de Imagenes
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)


Vue.config.productionTip = false

// TODO: Pasarlo a un filter.ts
Vue.filter('capitalize', (value: string) => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  render: (h: any) => h(App)
}).$mount('#app')
