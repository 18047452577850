import Vue from 'vue'
import Vuex from 'vuex'
import Seguridad from './modules/seguridad'
import Empresa from './modules/empresa'
import Galerias from './modules/galerias'
import Tarjetas from './modules/tarjetas'
import { getModule } from 'vuex-module-decorators'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		// aqu� se le pone el nombre al m�dulo, que debe ser igual al name: '' en el @Module si no no funciona, hay que leer los docs primero jajajaja
		seguridad: Seguridad,
		empresa: Empresa,
		galerias: Galerias,
		tarjetas: Tarjetas
	}
})

export default store

export const SeguridadModule = getModule(Seguridad, store)
export const EmpresaModule = getModule(Empresa, store)
export const GaleriasModule = getModule(Galerias, store)
export const TarjetasModule = getModule(Tarjetas, store)
