<template>
  <div class="container-fluid">
    <b-jumbotron>
			<h1>Bienvenid@ <small>Escoge una opci&oacute;n</small></h1>
		</b-jumbotron>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
