
















































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from 'vuex';

@Component({
	computed: {
		...mapGetters('seguridad', {
			isLoggedIn: 'GET_IS_LOGGED_IN',
			user: 'GET_USUARIO'
		})
	},
	methods: {
		...mapActions('seguridad', {
			doLogout: 'doLogout'
		})
	}
})
export default class ArgesNavbar extends Vue {
	created() {
		this.$store.dispatch('seguridad/doLoad')
	}
}
