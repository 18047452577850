





































































	import VueUploadMultipleImage from 'vue-upload-multiple-image'
	import { Component, Vue, Prop } from 'vue-property-decorator'
	import { mapState, mapActions } from 'vuex'
	import { ITarjetaImagen, ITarjetaFormulario } from '@/store/types/tarjetas'
	import { IRespuestaApi } from '@/store/types/comun';


	@Component({
		components: {
			VueUploadMultipleImage
		},
		computed: {
			...mapState('tarjetas', [
				'opcionesFormulario'
			])
		}
	})
	export default class ArgesTarjetasAgregarFotos extends Vue {

		galleryDir: string = ''
		images = new Array<ITarjetaImagen>()		
		showPrimary = false
		fields = ['Imagen', 'Guardar']
		// from mapState
		opcionesFormulario!: Array<ITarjetaFormulario>
		opcion!: ITarjetaFormulario

		@Prop()
		id!: number

		@Prop()
		seccion!: string

		constructor() {
			super()
		}

		mounted() {
			this.changeSize()
			this.galleryDir = process.env.VUE_APP_GALLERY_DIR || ""
			if (this.seccion) {
				//console.log(this.opcionesFormulario)
				if (this.opcionesFormulario) {
					this.opcionesFormulario.forEach((f: ITarjetaFormulario) => {
						if (f.clave === this.seccion) {
							this.opcion = f
						}
					})
				}
				
			}

			this.obtenerImagenes()
		}

		obtenerImagenes() {			
// obtener la lista de imagenes actuales de la tarjeta
			this.$store.dispatch('tarjetas/doGetImagenesTarjeta', this.id).then((response: any) => {
				var respuesta: IRespuestaApi = { codigo: 0, datos: '', mensaje: '' }
				if (response && response.data) {
					respuesta = response.data
					if (respuesta !== null && respuesta.codigo === 20000) {
						let datos = respuesta.datos as ITarjetaImagen[]
						if(datos && datos.length > 0) {
							datos.forEach( (d: ITarjetaImagen) => {
								this.images.push(d)
							})
						}
						
					} 
				}
			}).catch((reason: any) => {
				
			})			
		}

		changeSize() {
			// fix the image container
			let elems = document.querySelectorAll('.image-container')
			let index = 0
			let length = elems.length
			for (; index < length; index++) {
				(elems[index] as any).style.width = '500px';
				(elems[index] as any).style.height = '350px';
			}

		}

		uploadSuccess(formData: any, index: number, fileList: Array<ITarjetaImagen>) {
			// fix the image preview
			let prev = document.querySelectorAll('.preview-image')
			if (prev !== null) {
				let index = 0
				let length = prev.length
				for (; index < length; index++) {
					(prev[index] as any).style.height = '300px';
				}
			}

			if (!(fileList[index].path in this.images)) {
				this.images.push(fileList[index]);
			}
			//console.log(formData)
		}

		upload(i: ITarjetaImagen, index: number) {
			i.uploading = true
			i.tarjeta = this.seccion
			i.id = this.id
			Vue.set(this.images, index, i)
			this.$store.dispatch('tarjetas/doUploadMore', i).then((response: any) => {
				// console.log(response)
				var respuesta: IRespuestaApi = { codigo: 0, datos: '', mensaje: '' }

				if (response && response.data) {
					respuesta = response.data
					if (respuesta !== null && respuesta.codigo === 20000) {
						// let datos = response.datos
						i.uploading = false
						i.error = respuesta.mensaje
					} else if (respuesta !== null) {
						i.uploading = false
						i.error = response.data.mensaje
					} else {
						i.uploading = false
						i.error = 'Error al enviar los datos de Imagenes: No se obtuvo respuesta'
					}
				} else {
					i.uploading = false
					i.error = 'Error al enviar los datos de Imagenes: No se obtuvo respuesta'
				}
				Vue.set(this.images, index, i)
			}).catch((reason: any) => {
				i.uploading = false
				i.error = reason
				Vue.set(this.images, index, i)
			})
		}

		borrarImagenDelServidor(i: ITarjetaImagen, index: number) {
			i.uploading = true
			//i.id = this.id
			Vue.set(this.images, index, i)
			this.$store.dispatch('tarjetas/doDeleteImagenTarjeta', i.id).then((response: any) => {
				// console.log(response)
				var respuesta: IRespuestaApi = { codigo: 0, datos: '', mensaje: '' }

				if (response && response.data) {
					respuesta = response.data
					if (respuesta !== null && respuesta.codigo === 20000) {
						// let datos = response.datos
						i.uploading = false
						i.error = respuesta.mensaje
					} else if (respuesta !== null) {
						i.uploading = false
						i.error = response.data.mensaje
					} else {
						i.uploading = false
						i.error = 'Error al enviar los datos de Imagenes: No se obtuvo respuesta'
					}
				} else {
					i.uploading = false
					i.error = 'Error al enviar los datos de Imagenes: No se obtuvo respuesta'
				}
				Vue.set(this.images, index, i)
			}).catch((reason: any) => {
				i.uploading = false
				i.error = reason
				Vue.set(this.images, index, i)
			})
		}

		// remove pero del control de subir imagenes
		beforeRemove(index: number, done: any, fileList: Array<ITarjetaImagen>) {
			let img = fileList[index];
			if (img && img.path) {
				var r = confirm('Quitar imagen de lista a subir?')
				if (r == true) {
					for (let i = 0; i < this.images.length; i++) {
						if (this.images[i].path.substring(0, 60) === img.path.substring(0, 60)) {
							this.images.splice(i, 1)
							break;
						}
					}
					done()
				}
			}
		}
	}
