<template>
  <div id="app">
		<arges-navbar></arges-navbar>
    <router-view></router-view>
  </div>
</template>

<script>

	import ArgesNavbar from '@/components/ArgesNavbar.vue'

	export default {
		name: 'app',
		components: {
			ArgesNavbar
		},
		created() {
			// console.log('app created')
			this.$store.dispatch('seguridad/doLoad')
		}
	}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
