<template>
	<div class="container-fluid">
		<b-jumbotron>
			<h1>Secci&oacute;n 2 <small>Habitaciones y Suites Hotel Arges</small></h1>
		</b-jumbotron>
		<b-jumbotron>
			<h2>Seccion 2.1 Habitaciones</h2>
			<img alt="seccion 2.1" src="../../assets/seccion2.1.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '2.1'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="2.1"></arges-tarjetas-lista>
		</b-jumbotron>		
	</div>
</template>

<script>
	
	import ArgesTarjetasLista from '@/components/tarjetas/ArgesTarjetasLista.vue'

	export default {
		name: 'Seccion2View',
		components: {
			ArgesTarjetasLista
		}
	}
</script>
