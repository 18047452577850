


































import { Component, Vue, Prop } from 'vue-property-decorator'
import { IRespuestaApi } from '@/store/types/comun'
import { IArchivoEmpresa, IRutasArchivosEmpresa } from '@/store/types/empresa';
import { mapGetters } from 'vuex';

@Component({
	data: () => {
		return {
			file: null
		}
	},
	computed: {
		...mapGetters('empresa', {
			rutasArchivos: 'GET_RUTAS_ARCHIVOS_EMPRESA' // <- for runtime
		})
	}
})
export default class ArchivoEmpresa extends Vue {

	file: any
	progress: number = 0 
	message: string = ''
	uploading: boolean = false
	rutasArchivos!: IRutasArchivosEmpresa[]

	@Prop()
	campoEmpresa!: string

	constructor() {
		super()
	}

	// Lifecycle hook
	mounted() {
		
	}

	upload() {
		let archivoEmpresa: IArchivoEmpresa = {
			file: this.file,
			campo: this.campoEmpresa
		}

		this.message = ''
		this.uploading = true


		this.$store.dispatch('empresa/doUpload', archivoEmpresa).then((response: any) => {
			// console.log(response)
			var respuesta: IRespuestaApi = { codigo : 0, datos: '', mensaje: ''}

			if (response && response.data) {
				respuesta = response.data
				if (respuesta !== null && respuesta.codigo === 20000) {
					// let datos = response.datos
					this.uploading = false
					this.message = respuesta.mensaje
				} else if (respuesta !== null) {
					this.uploading = false
					this.message = response.data.mensaje
				} else {
					this.uploading = false
					this.message = 'Error al enviar los datos de Imagenes: No se obtuvo respuesta'
				}
			} else {
				this.uploading = false
				this.message = 'Error al enviar los datos de Imagenes: No se obtuvo respuesta'
			}
			
		}).catch((reason: any) => {
			this.uploading = false
			this.message = reason
			
		})
	}

}
