





































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters, mapActions } from 'vuex'
import { IGaleria } from '@/store/types/galerias'

@Component({
	computed: {
		...mapGetters('galerias', [				
		]),
		...mapActions('galerias', [				
		]),
	}
})
export default class ArgesGaleriasLista extends Vue {

	error: string = ''
	galleryDir: string = ''
	galeriaSeleccionada: string = ''
	galerias = new Array<IGaleria>()
	fields = ['Imagen', 'Titulos', 'Descripcion', 'Opciones']

	@Prop()
	seccion!: string

	constructor() {
		super()			
	}

	mounted() {
		this.galleryDir = process.env.VUE_APP_GALLERY_DIR;			
	}

	doGetGaleriasFromServer() {
		this.$store.dispatch('galerias/doGetGaleriasFromServer', this.seccion)
			.then((response: any) => {
				this.error = ''
				if (response && response.data) {
					this.galerias = response.data.datos
				}
			}).catch((reason: any) => {
				this.error = reason;
			})
	}

	doEditGaleria(item: IGaleria, index: number) {
		item.uploading = true;
		Vue.set(this.galerias, index, item);
		this.$store.dispatch('galerias/doEditGaleria', item)
			.then((response: any) => {
				if (response && response.data) {
					item.uploading = false;
					item.error = response.data.mensaje;
					Vue.set(this.galerias, index, item);
				}
			}).catch((reason: any) => {
				item.uploading = false;
				item.error = reason;
				Vue.set(this.galerias, index, item);
			})
	}

	doDeleteGaleria(item: IGaleria, index: number) {
		item.uploading = true;
		Vue.set(this.galerias, index, item);
		this.$store.dispatch('galerias/doDeleteGaleria', item.id)
			.then((response: any) => {
				if (response && response.data) {
					item.uploading = false;
					item.error = response.data.mensaje;
					Vue.set(this.galerias, index, item);
				}
			}).catch((reason: any) => {
			})
	}
}
