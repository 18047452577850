


















































import { Component, Vue } from 'vue-property-decorator';
import { ILogin } from '@/store/types/seguridad';
import { mapGetters, mapActions } from 'vuex';

@Component({
	computed: {
		...mapGetters('seguridad', {
			isLoggedIn: 'GET_IS_LOGGED_IN',
			error: 'GET_ERROR',
			user: 'GET_USUARIO'
		})
	},
	methods: {
		...mapActions('seguridad', {
			doLogin: 'doLogin'
		})
	}
})
export default class Login extends Vue {
	loginData?: ILogin;

	created() {
		this.loginData = { usuario: '', pwd: '' } as ILogin;
	}
}
