<template>
	<div class="container-fluid">
		<b-jumbotron>
			<h1>Archivos de Empresa Hotel Arges <br /><small>Men&uacute;, pol&iacute;ticas de privacidad y reservaciones</small></h1>
		</b-jumbotron>
		<b-jumbotron header="Menu Restaurante (espanol)" bg-variant="info" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="menu_es"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Menu Restaurante (ingles)" bg-variant="info" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="menu_en"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Politicas en espanol" bg-variant="success" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="politicas"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Politicas en ingles" bg-variant="success" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="policies"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Reservaciones ante huracan (espanol)" bg-variant="danger" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="huracan"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Reservaciones ante huracan (ingles)" bg-variant="danger" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="hurricane"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Informacion de COVID (espanol)" bg-variant="danger" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="covid_es"></archivo-empresa>
		</b-jumbotron>
		<b-jumbotron header="Informacion de COVID (ingles)" bg-variant="danger" text-variant="white" border-variant="dark">
			<archivo-empresa campo-empresa="covid_en"></archivo-empresa>
		</b-jumbotron>
	</div>
</template>

<script>

	import ArchivoEmpresa from '@/components/empresa/ArchivoEmpresa.vue'

	export default {
		name: 'EmpresaView',
		data: () => {
			return {
			}
		},
		components: {
			ArchivoEmpresa
		},
		mounted() {
			this.$store.dispatch('empresa/doGetRutasArchivos')
		}
	}
</script>

<style scoped lang="scss">
</style>