import Axios from 'axios'
import { IState, IRespuestaApi } from '@/store/types/comun'
import { IArchivoEmpresa, IRutasArchivosEmpresa } from '@/store/types/empresa'
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({
	name: 'empresa',
	namespaced: true,
})
export default class Empresa extends VuexModule implements IState {

	// properties
	error = ''
	public rutasArchivos = {} as IRutasArchivosEmpresa

	get GET_RUTAS_ARCHIVOS_EMPRESA() {
		return this.rutasArchivos
	}


	// Mutations
	@Mutation
	protected SET_ERROR(error: string) {
		this.error = error
	}

	@Mutation
	public SET_RUTAS_ARCHIVOS_EMPRESA(a: IRutasArchivosEmpresa) {
		this.rutasArchivos = a
	}



	@Action
	protected async doGetRutasArchivos() {
		const response = await Axios.get('empresa')
		if (response && response.data && response.data !== null && response.data.codigo === 20000) {
			this.context.commit('SET_RUTAS_ARCHIVOS_EMPRESA', response.data.datos)
			//this.context.commit('SET_MENSAJE', response.data.mensaje)
		} 
	}

	@Action
	protected async doUpload(archivoEmpresa: IArchivoEmpresa) {
		// convert file to 64 Base Encoding text and send JSON to WEB API

		archivoEmpresa.type = archivoEmpresa.file.type
		archivoEmpresa.name = archivoEmpresa.file.name

		let content = await this.context.dispatch('doGetFileAsDataURL', archivoEmpresa.file)
	
		archivoEmpresa.base64 = content as string

		delete archivoEmpresa.file
		return await Axios.post('empresa/archivo/upload', archivoEmpresa)
	}

	@Action
	protected async doGetFileAsDataURL(file: any) {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		return new Promise((resolve, reject) => {
			reader.onloadend = (event: any) => {
				resolve(event.target.result)
			}
		})
	}

}