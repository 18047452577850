<template>
  <div class="container">
    <arges-login></arges-login>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  name: 'login',
  components: {
    'arges-login': Login
  }
}
</script>
