

















































































import ArgesGaleriasLista from '@/components/galerias/ArgesGaleriasLista.vue'
import ArgesTarjetasLista from '@/components/tarjetas/ArgesTarjetasLista.vue'

export default {
	name: 'Seccion1View',
	components: {
		ArgesGaleriasLista,
		ArgesTarjetasLista
	}
}
