import Axios from 'axios'
import { IState, IRespuestaApi } from '@/store/types/comun'
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ITarjeta, ITarjetaImagen, ITarjetaImagenUpload, ITarjetaImagenUploadMore, ITarjetaFormulario } from '@/store/types/tarjetas';

@Module({
	name: 'tarjetas',
	namespaced: true,
})
export default class Tarjetas extends VuexModule implements IState {

	// properties
	error = ''	


	opcionesFormulario: ITarjetaFormulario[] = [
		{ clave: '1.2', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: false, mostrarLink: false, mostrarLista: false },
		{ clave: '1.3', mostrarTitulo: true, mostrarSubtitulo: false, mostrarDescripcion: false, mostrarPrecio: true, mostrarLink: true, mostrarLista: true },
		// la sección 3.1 es igual a la sección 1.4
		{ clave: '1.4', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
		{ clave: '1.5', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
		{ clave: '1.6', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: false, mostrarLink: false, mostrarLista: false },
		{ clave: '2.1', mostrarTitulo: true, mostrarSubtitulo: false, mostrarDescripcion: false, mostrarPrecio: true, mostrarLink: true, mostrarLista: true },
		{ clave: '4.1', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
		{ clave: '4.2', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
		{ clave: '4.3', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
		{ clave: '4.4', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
		{ clave: '4.5', mostrarTitulo: true, mostrarSubtitulo: true, mostrarDescripcion: true, mostrarPrecio: true, mostrarLink: false, mostrarLista: false },
	]

	// Mutations
	@Mutation
	protected SET_ERROR(error: string) {
		this.error = error
	}

	// Actions
	@Action
	protected async doUpload(i: ITarjetaImagen) {
		// convertir a variables de imagen upload parameter
		let upload = new Array<ITarjetaImagenUpload>()
		// genera un nombre aleatorio eliminar necesidad de escribir u nnombre para el usuario
		var rangoDesde = 65, rangoHasta = 90, caption = '', number = 0
		while (caption.length < 10) {
			number = (Math.floor((Math.random() * 26) + 1)) + 64
			caption += String.fromCharCode(number)
		}

		upload.push(<ITarjetaImagenUpload>{
			id: i.id,
			caption: caption, // antes i.caption, se quitó para quitar captura extra por el usuario
			tarjeta: i.tarjeta, // viene de seccion o clave de grupo en la base de datos
			titulo: i.titulo,
			title: i.title,
			subtitulo: i.subtitulo,
			subtitle: i.subtitle,
			descripcion: i.descripcion,
			description: i.description,
			link: i.link,
			precio: i.precio,
			descuento: i.descuento,
			lista: i.lista,
			list: i.list,
			data: i.path
		})
		// how about each image gets its own post?
		return await Axios.post('tarjetas/upload', upload)
	}

	// Actions
	@Action
	protected async doUploadMore(i: ITarjetaImagen) {
		// convertir a variables de imagen upload parameter
		let upload = new Array<ITarjetaImagenUploadMore>()
		// genera un nombre aleatorio eliminar necesidad de escribir u nnombre para el usuario
		var rangoDesde = 65, rangoHasta = 90, caption = '', number = 0
		while (caption.length < 10) {
			number = (Math.floor((Math.random() * 26) + 1)) + 64
			caption += String.fromCharCode(number)
		}

		upload.push(<ITarjetaImagenUploadMore>{
			id: i.id,
			data: i.path
		})
		// how about each image gets its own post?
		return await Axios.post('tarjetas/upload-more', upload)
	}
	
	@Action
	protected async doGetTarjetasFromServer(tarjetaSeleccionada: string) {
		if (tarjetaSeleccionada === '3.1') { tarjetaSeleccionada = '1.4'; }
		if (tarjetaSeleccionada === '2.1') { tarjetaSeleccionada = '1.3'; }

		return await Axios.get('tarjetas', {
			params: {
				'filtro': tarjetaSeleccionada
			}
		})
	}

	@Action
	protected async doGetImagenesTarjeta(idTarjeta: number) {
		// console.log('buscar fotos relacionadas a tarjeta')
		return await Axios.get('tarjetas/'+idTarjeta.toString()+'/imagenes', {
			params: {
				'id_tarjeta': idTarjeta
			}
		})
	}

	@Action
	protected async doEditTarjeta(tarjeta: ITarjeta) {

		var g = {
			id: tarjeta.id,
			titulo: tarjeta.titulo,
			title: tarjeta.title,
			subtitulo: tarjeta.subtitulo,
			subtitle: tarjeta.subtitle,
			descripcion: tarjeta.descripcion,
			description: tarjeta.description,
			lista: tarjeta.lista,
			list: tarjeta.list,
			precio: tarjeta.precio,
			descuento: tarjeta.descuento,
			link: tarjeta.link
		};

		return await Axios.post('tarjetas/' + tarjeta.id.toString(), g)
	}

	@Action
	protected async doDeleteImagenTarjeta(id_imagen: number) {
		return await Axios.delete('tarjetas/imagen/' + id_imagen.toString(), {
			data: {
				'id_imagen': id_imagen
			}
		})
	}

	@Action
	protected async doDeleteTarjeta(id: number) {
		return await Axios.delete('tarjetas/' + id.toString(), {
			data: {
				'id': id
			}
		})
	}

	
}