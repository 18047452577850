<template>
	<div class="container-fluid">
		<b-jumbotron>
			<h1>Secci&oacute;n 4 <small>Encabezados de Secciones</small></h1>
		</b-jumbotron>
		<b-jumbotron>
			<h2>Seccion 4.1 Habitaciones</h2>
			<img alt="seccion 4.1" src="../../assets/seccion4.1.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '4.1'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="4.1"></arges-tarjetas-lista>
		</b-jumbotron>		
		<b-jumbotron>
			<h2>Seccion 4.2 Restaurante Mantura</h2>
			<img alt="seccion 4.2" src="../../assets/seccion4.2.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '4.2'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="4.2"></arges-tarjetas-lista>
		</b-jumbotron>
		<b-jumbotron>
			<h2>Seccion 4.3 Servicios</h2>
			<img alt="seccion 4.3" src="../../assets/seccion4.3.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '4.3'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="4.3"></arges-tarjetas-lista>
		</b-jumbotron>		
		<b-jumbotron>
			<h2>Seccion 4.4 Servicios</h2>
			<img alt="seccion 4.4" src="../../assets/seccion4.4.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '4.4'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="4.4"></arges-tarjetas-lista>
		</b-jumbotron>	
		<b-jumbotron>
			<h2>Seccion 4.5 Servicios</h2>
			<img alt="seccion 4.5" src="../../assets/seccion4.5.png" />
			<b-row>
				<b-col>&nbsp;</b-col>
				<b-col class="my-4">
					<b-button variant="outline-success" :to="{name: 'tarjetas-alta', params: {seccion: '4.5'} }">Agregar una imagen a la secci&oacute;n</b-button>
				</b-col>
				<b-col>&nbsp;</b-col>
			</b-row>
			<arges-tarjetas-lista seccion="4.5"></arges-tarjetas-lista>
		</b-jumbotron>	
	</div>
</template>

<script>
	
	import ArgesTarjetasLista from '@/components/tarjetas/ArgesTarjetasLista.vue'

	export default {
		name: 'Seccion4View',
		components: {
			ArgesTarjetasLista
		}
	}
</script>
