import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import EmpresaView from '@/views/empresa/EmpresaView.vue'
import Seccion1View from '@/views/seccion1/Seccion1View.vue'
import Seccion2View from '@/views/seccion2/Seccion2View.vue'
import Seccion3View from '@/views/seccion3/Seccion3View.vue'
import Seccion4View from '@/views/seccion4/Seccion4View.vue'
import ArgesGaleriasAlta from '@/components/galerias/ArgesGaleriasAlta.vue'
import ArgesGaleriasEditarFoto from '@/components/galerias/ArgesGaleriasEditarFoto.vue'
import ArgesTarjetasAlta from '@/components/tarjetas/ArgesTarjetasAlta.vue'
import ArgesTarjetasEditarFoto from '@/components/tarjetas/ArgesTarjetasEditarFoto.vue'
import ArgesTarjetasAgregarFotos from '@/components/tarjetas/ArgesTarjetasAgregarFotos.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			guest: true
		}
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/empresa',
		name: 'empresa',
		component: EmpresaView,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/seccion-1',
		name: 'seccion-1',
		component: Seccion1View,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/seccion-2',
		name: 'seccion-2',
		component: Seccion2View,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/seccion-3',
		name: 'seccion-3',
		component: Seccion3View,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/seccion-4',
		name: 'seccion-4',
		component: Seccion4View,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/galerias-alta/:seccion',
		name: 'galerias-alta',
		component: ArgesGaleriasAlta,
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/galerias-editar-foto/:seccion/:id',
		name: 'galerias-editar-foto',
		component: ArgesGaleriasEditarFoto,
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/tarjetas-alta/:seccion',
		name: 'tarjetas-alta',
		component: ArgesTarjetasAlta,
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/tarjetas-editar-foto/:seccion/:id',
		name: 'tarjetas-editar-foto',
		component: ArgesTarjetasEditarFoto,
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/tarjetas-agregar-fotos/:seccion/:id',
		name: 'tarjetas-agregar-fotos',
		component: ArgesTarjetasAgregarFotos,
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		// will match everything
		path: '*',
		meta: {
			requiresAuth: true
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


const ls_user_item = 'ha-seguridad'

router.beforeEach((to: any, from: any, next: any) => {
	// TODO: cargar del store el data actual y determinar si est� logged in
	let item = localStorage.getItem(ls_user_item)
	if (to.matched.some((record: any) => record.meta.requiresAuth)) {
		if (!item) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			})
		} else {

			let user = JSON.parse(item == null ? "" : item.toString())
			if (user.token) {
				// TODO: validar token? validar permisos?
				next()
			} else {
				next({
					path: '/login',
					params: { nextUrl: to.fullPath }
				})
			}
			//if (to.matched.some(record => record.meta.is_admin)) {
			//	if (user.is_admin == 1) {
			//		next()
			//	}
			//	else {
			//		next({ name: 'userboard' })
			//	}
			//} else {
			//	next()
			//}
		}
	} else if (to.matched.some((record: any) => record.meta.guest)) {
		if (item == null) {
			next()
		}
		else {
			next({ name: 'home' })
		}
	} else {
		next()
	}
})

export default router
