import Axios from 'axios'
import { IState, IRespuestaApi } from '@/store/types/comun'
import { IGaleria, IGaleriaImagen, IGaleriaImagenUpload } from '@/store/types/galerias'
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({
	name: 'galerias',
	namespaced: true,
})
export default class Galerias extends VuexModule implements IState {

	// properties
	error = ''

	// Mutations
	@Mutation
	protected SET_ERROR(error: string) {
		this.error = error
	}

	// Actions
	@Action
	protected async doGetGaleriasFromServer(galeriaSeleccionada: string) {
		return await Axios.get('galerias', {
			params: {
				'filtro': galeriaSeleccionada
			}
		})
	}

	@Action
	protected async doEditGaleria(galeria: IGaleria) {

		var g = {
			id: galeria.id,
			titulo: galeria.titulo,
			title: galeria.title,
			subtitulo: galeria.subtitulo,
			subtitle: galeria.subtitle,
			descripcion: galeria.descripcion,
			description: galeria.description,
			orden: galeria.orden
		};

		return await Axios.post('galerias/' + galeria.id.toString(), g)
	}

	@Action
	protected async doDeleteGaleria(id: number) {
		return await Axios.delete('galerias/' + id.toString(), {
			data: {
				'id': id
			}
		})
	}


	
	@Action
	protected async doUpload(i: IGaleriaImagen) {
		// convertir a variables de imagen upload parameter
		let upload = new Array<IGaleriaImagenUpload>()
		// genera un nombre aleatorio eliminar necesidad de escribir u nnombre para el usuario
		var rangoDesde = 65, rangoHasta = 90, caption = '', number = 0
		while (caption.length < 10) {
			number = (Math.floor((Math.random() * 26) + 1)) + 64
			caption += String.fromCharCode(number)
		}

		upload.push(<IGaleriaImagenUpload>{
			id: i.id,
			caption: caption, // antes i.caption
			galeria: i.galeria,
			titulo: i.titulo,
			title: i.title,
			subtitulo: i.subtitulo,
			subtitle: i.subtitle,
			descripcion: i.descripcion,
			description: i.description,
			data: i.path
		})
		// how about each image gets its own post?
		return await Axios.post('galerias/upload', upload)
	}
	
}