import router from '@/router'
import Axios from 'axios';
import { ILogin, IUsuario, ISeguridadSate } from '@/store/types/seguridad';
import { IRespuestaApi } from '@/store/types/comun';
import { Module, getModule, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export const seguridadCleanState: ISeguridadSate = {
	error: '',
	is_logged_in : false,
	usuario : <IUsuario>{},
	token : '',
	permisos :[]
}

@Module({ 
	name: 'seguridad',
	namespaced: true, 
})
export default class Seguridad extends VuexModule {

	local_storage_name: string = 'ha-seguridad';

	// siempre tendr�amos un data de tipo "IContextoState" para serializar 
	data: ISeguridadSate = {
		error: '',
		is_logged_in: false,
		usuario: <IUsuario>{},
		token: '',
		permisos: new Array<string>(),
	} 

	get GET_DATA(): ISeguridadSate {
		return this.data
	}

	get GET_LOCALSTORAGE_NAME(): string {
		return this.local_storage_name
	}


	get GET_TOKEN(): string {
		return this.data.token || ''
	}

	get GET_USUARIO(): IUsuario {
		return this.data.usuario || <IUsuario>{}
	}

	get GET_IS_LOGGED_IN(): boolean {
		return this.data.is_logged_in || false
	}

	get GET_PERMISOS() {
		return this.data.permisos || []
	}

	get GET_ERROR(): string {
		return this.data.error || ''
	}

	@Mutation
	SET_IS_LOGGED_IN(is_logged_in: boolean) {
		this.data.is_logged_in = is_logged_in
	}

	@Mutation
	SET_USUARIO(user: IUsuario) {
		this.data.usuario = user
	}

	@Mutation
	SET_TOKEN(token: string) {
		this.data.token = token
	}

	@Mutation
	SET_ERROR(error: string) {
		this.data.error = error
	}

	@Mutation
	SET_PERMISOS(permisos: Array<string>) {
		this.data.permisos = permisos
	}

	@Mutation
	SET_DATA(data: ISeguridadSate) {
		this.data = data
	}

	@Action
	async doLoad(){
		let data: ISeguridadSate = JSON.parse(localStorage.getItem(this.context.getters['GET_LOCALSTORAGE_NAME']) || JSON.stringify(seguridadCleanState)) || seguridadCleanState
		
		this.context.commit('SET_DATA', data)
		//this.context.commit('SET_ERROR', data.error)
		//this.context.commit('SET_IS_LOGGED_IN', data.is_logged_in)
		//this.context.commit('SET_USUARIO', data.usuario)
		//this.context.commit('SET_TOKEN', data.token)
		//this.context.commit('SET_PERMISOS', data.permisos || [])
	}

	@Action
	async doLogin(login: ILogin) {
		this.context.commit('SET_ERROR', '')
		let response = await Axios.post('usuarios/login', login);
		if (response && response.data && response.data !== null && response.data.codigo === 20000) {
			let datos = response.data.datos
			let data = <ISeguridadSate>{
				error: '',
				is_logged_in: true,
				token: datos.token,
				permisos: new Array<string>(),
				usuario: <IUsuario>{
					mail: datos.mail,
					usuario: datos.user,
					nombre: datos.nombre,
					rol: '', // pendiente el rol
					oficina: datos.oficina,
					id: datos.id
				}
			}
			this.context.commit('SET_DATA', data)
			localStorage.setItem(this.context.getters['GET_LOCALSTORAGE_NAME'], JSON.stringify(data)) 
			router.push({ name: 'home' })
		}
		else {
			this.context.commit('SET_ERROR', response.data.mensaje)
		}
	}

	@Action
	async doLogout() {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		let user: IUsuario = this.context.getters['GET_USUARIO']
		let parametros = { 'usuario': user.usuario }
		this.context.commit('SET_DATA', seguridadCleanState) // no setear a null, porque se pierde la reactividad
		localStorage.removeItem(this.context.getters['GET_LOCALSTORAGE_NAME']) // store the token in localstorage
		router.push({ name: 'login' })		
		let response = await Axios.post('usuarios/logout', parametros);
	}
}