



























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'
import { ITarjeta, ITarjetaFormulario } from '@/store/types/tarjetas'


@Component({
	computed: {
		...mapState('tarjetas', [
			'opcionesFormulario'
		]),
		...mapActions('tarjetas', [				
		]),
	}
})
export default class ArgesTarjetasLista extends Vue {

	galleryDir: string = ''
	tarjetaSeleccionada: string = ''
	tarjetas = new Array<ITarjeta>()

	// textos de campos para algunas secciones
	campoTextoPrecio = 'Precio'
	campoTextoDescuento = 'Descuento'

	// from mapState
	opcionesFormulario!: Array<ITarjetaFormulario>
	opcion!: ITarjetaFormulario
	fields = ['Imagen', 'Titulo', 'Descripcion', 'Precio', 'Opciones']

	@Prop()
	seccion!: string

	constructor() {
		super()
	}

	mounted() {
		this.galleryDir = process.env.VUE_APP_GALLERY_DIR
		if (this.seccion) {
			// console.log(this.opcionesFormulario)
			if (this.opcionesFormulario) {
				this.opcionesFormulario.forEach((f: ITarjetaFormulario) => {
					if (f.clave === this.seccion) {
						this.opcion = f
					}
				})
			}
			if(this.seccion.startsWith('4.')) {
				this.campoTextoPrecio = 'Tamaño Fuente (100% valor default)'
				this.campoTextoDescuento = 'Color de Fuente'
			}
		}
	}

	doGetTarjetasFromServer() {			
		this.$store.dispatch('tarjetas/doGetTarjetasFromServer', this.seccion)
			.then((response: any) => {
				if (response && response.data) {
					this.tarjetas = response.data.datos
				}
			}).catch((reason: any) => {
		})
	}

	doEditTarjeta(item: ITarjeta, index: number) {
		item.uploading = true
		Vue.set(this.tarjetas, index, item)
		this.$store.dispatch('tarjetas/doEditTarjeta', item)
			.then((response: any) => {
				if (response && response.data) {
					item.uploading = false
					item.error = response.data.mensaje
					Vue.set(this.tarjetas, index, item)
				}
			}).catch((reason: any) => {
				item.uploading = false
				item.error = reason
				Vue.set(this.tarjetas, index, item)
			})
	}


	doDeleteTarjeta(item: ITarjeta, index: number) {
		item.uploading = true
		Vue.set(this.tarjetas, index, item)
		this.$store.dispatch('tarjetas/doDeleteTarjeta', item.id)
			.then((response: any) => {
				if (response && response.data) {
					item.uploading = false
					item.error = response.data.mensaje
					Vue.set(this.tarjetas, index, item)
				}
			}).catch((reason: any) => {
			})
	}
}
